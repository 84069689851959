.request-catalog {
    padding-top: 91px;

    .top-content {
        text-align: center;

        .body {
            padding: 0 16px;
            margin: 50px auto 0;
            max-width: 668px;
            font-size: 16px;

            p {
                font-size: inherit;
            }
        }
    }

    .sg-form {
        padding: 0;
        margin-top: 24px;

        form > .form-row:nth-child(2n+1) {
            clear: left;
        }

        @include bp(max-width, $bp-small) {
            .form-row {
                width: 100%;
                margin-left: 0;
            }
        }
    }
}
