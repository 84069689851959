$fslf: find-store-locator-form;

.pt_store-locator {
    color: $brand-v2-off-black;
}

.store-locator-primary {
    padding: 0 30px;
    margin: 0 auto;
    max-width: 1238px;
}

.store-locator-head {
    margin: 20px auto 30px;
    text-align: center;

    @include bp(min-width, $bp-medium) {
        margin: 30px auto 40px;
    }

    h1 {
        font: normal normal 28px/34px $f-classical-normal;
        letter-spacing: initial;
        margin-bottom: 15px;
        text-transform: initial;

        @include bp(min-width, $bp-medium) {
            font: normal normal 40px/48px $f-classical-normal;
        }
    }

    p {
        font: normal normal 16px/22px $f-avenir;
        margin: 0;
    }
}

.find-store-locator-form-wrapper {
    color: $black-two;
    text-transform: uppercase;

    &.active {
        .#{$fslf} {
            display: block;
        }
    }
}

.find-store-locator-form-title {
    border: 1px solid $store-locator-select-border-color;
    cursor: pointer;
    font: normal normal 18px/25px $f-avenir;
    height: 40px;
    padding: 9px 20px;
    position: relative;

    &::after {
        border-bottom: 2px solid $dark-sage;
        border-left: 2px solid $dark-sage;
        content: "";
        height: 10px;
        position: absolute;
        right: 18px;
        top: 12px;
        transform: rotate(315deg);
        width: 10px;

        @include bp(min-width, $bp-medium) {
            display: none;
        }
    }

    @include bp(min-width, $bp-medium) {
        border: 0 none;
        cursor: initial;
        font: normal normal 20px/28px $f-avenir;
        height: auto;
        padding: 0;
    }
}

.#{$fslf} {
    display: none;
    margin-top: 20px;

    @include bp(min-width, $bp-medium) {
        display: block;
        margin-top: 30px;
    }

    &__inner {
        display: flex;
        flex-direction: column;

        @include bp(min-width, $bp-medium) {
            flex-direction: row;
        }
    }

    &__inputs-wrapper {
        font: normal normal 16px/22px $f-avenir;
        position: relative;

        &.active {
            .#{$fslf} {
                &__inputs {
                    display: block;
                }

                &__inputs-label {
                    &::after {
                        top: 17px;
                        transform: rotate(135deg);
                    }
                }
            }
        }

        &.selected {
            .#{$fslf} {
                &__inputs-label {
                    background: $store-locator-select-selected-background-color;
                }
            }
        }
    }

    &__inputs-label {
        border: 1px solid $store-locator-select-border-color;
        cursor: pointer;
        height: 40px;
        padding: 9px 20px;
        position: relative;
        width: 100%;

        @include bp(min-width, $bp-medium) {
            width: 170px;
        }

        @include bp(min-width, $bp-large) {
            width: 220px;
        }

        &::before {
            background: $store-locator-select-pipe-background-color;
            content: "";
            height: 22px;
            position: absolute;
            right: 46px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
        }

        &::after {
            border-bottom: 2px solid $dark-sage;
            border-left: 2px solid $dark-sage;
            content: "";
            height: 10px;
            position: absolute;
            right: 18px;
            top: 12px;
            transform: rotate(315deg);
            width: 10px;
        }
    }

    &__inputs {
        background: $white;
        border: 1px solid $store-locator-select-border-color;
        display: none;
        left: 0;
        max-height: 162px;
        min-width: 100%;
        overflow: hidden auto;
        position: absolute;
        top: 60px;
        z-index: 1;

        @include bp(min-width, $bp-medium) {
            min-width: 220px;
        }

        .form-row {
            align-items: center;
            display: flex;
            height: 40px;
            margin: 0;
            padding-left: 20px;

            &.selected {
                background: $white-smoke;
            }

            + .form-row {
                border-top: 1px solid $store-locator-select-border-color;
            }

            .checkbox-label {
                color: inherit;
                font: inherit;
                padding-left: 37px;

                &::before,
                &::after {
                    height: 17px;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 17px;
                }

                &::before {
                    border-color: $very-light-gray-v2;
                }

                &::after {
                    background: url("../images/checkmark-checkbox.svg") center / 13px no-repeat;
                    content: "";
                    display: none;
                }

                &.checked {
                    &::after {
                        display: block;
                    }
                }
            }
        }
    }

    &__or {
        align-self: center;
        font: normal normal 16px/22px $f-avenir;
        margin: 5px auto;
        text-transform: none;

        @include bp(min-width, $bp-medium) {
            align-self: flex-end;
            margin: 2px 10px;
        }

        @include bp(min-width, $bp-large) {
            margin: 2px 20px;
        }
    }

    &__clear-filters {
        align-self: flex-start;
        text-transform: none;
        margin-top: 30px;

        @include bp(min-width, $bp-medium) {
            align-self: flex-end;
            margin: 0 0 5px 20px;
        }

        @include bp(min-width, $bp-large) {
            margin-left: 40px;
        }
    }
}

.stores-list-wrapper {
    margin-top: 40px;
    position: relative;

    @include bp(min-width, $bp-medium) {
        margin-top: 50px;
    }
}

.stores-list-loading {
    background: rgba(238, 238, 238, 0.5);
    bottom: 0;
    display: none;
    justify-content: center;
    left: 0;
    min-height: 100px;
    padding: 30px;
    position: absolute;
    right: 0;
    top: 0;

    &.active {
        display: flex;
    }

    .spinner {
        margin: 0;
    }
}

.store-locator-group {
    + .store-locator-group {
        margin-top: 20px;

        @include bp(min-width, $bp-medium) {
            margin-top: 30px;
        }
    }
}

.store-locator-header {
    h3 {
        color: inherit;
        font: normal italic 26px/21px $f-classical;
        letter-spacing: 0.05em;
        margin: 0 0 20px;

        @include bp(min-width, $bp-medium) {
            font: normal italic 30px/36px $f-classical;
        }
    }

    p {
        font: normal normal 18px/25px $f-avenir;
        margin: 0 0 10px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 20px;
        }
    }
}

.store-location-results {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.store-information {
    font: normal normal 14px/19px $f-avenir;
    padding: 20px 10px;
    width: 100%;

    @include bp(min-width, $bp-medium) {
        width: 25%;
    }

    + .store-information {
        border-top: 1px solid $store-locator-stores-divider-color;

        @include bp(min-width, $bp-medium) {
            border-top: 0 none;
        }
    }

    p {
        font: inherit;
    }

    .store-name {
        font: 500 normal 20px/27px $f-avenir;
        margin-bottom: 20px;
    }

    .store-address {
        margin-bottom: 15px;

        span {
            display: inline-block;
        }
    }

    .store-phone,
    .store-email {
        margin-bottom: 5px;
        word-wrap: break-word;

        &.has-email {
            margin-bottom: 0;
        }
    }

    .store-hours {
        margin-bottom: 20px;
    }

    .store-detail-link {
        color: $california-gold;

        a {
            color: inherit;

            &:active,
            &:focus {
                color: inherit;
            }
        }
    }
}
