.ui-dialog {
    &.furniture-protection-pricing-ui-dialog {
        width: 330px !important;

        @include bp(min-width, $bp-medium) {
            width: 496px !important;
        }

        .ui-widget-header {
            display: none;
        }

        .ui-dialog-content {
            padding: 0;
        }
    }

    &.furniture-protection-see-details-ui-dialog {
        width: 330px !important;

        @include bp(min-width, $bp-medium) {
            width: 592px !important;
        }

        .ui-widget-header {
            display: none;
        }

        .ui-dialog-content {
            padding: 0;
        }
    }
}

.furniture-pricing-dialog {
    color: $black;
    padding: 48px 10px 20px;

    @include bp(min-width, $bp-medium) {
        padding: 41px 40px 24px 14px;
    }

    &__close {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 25px;
        height: 25px;
        transform: rotate(45deg);
        cursor: pointer;

        &::before,
        &::after {
            content: "";
            position: absolute;
            background: $black;
        }

        &::before {
            height: 1px;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &::after {
            width: 1px;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__sup-title {
        font: 500 normal 12px/14px $f-future-book;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        margin-bottom: 14px;
        padding-left: 10px;

        @include bp(min-width, $bp-medium) {
            padding-left: 20px;
        }
    }

    &__title {
        font: normal normal 24px/24px $f-classical-normal;
        margin-bottom: 32px;
        padding-left: 10px;

        @include bp(min-width, $bp-medium) {
            font: normal normal 30px/30px $f-classical-normal;
            padding-left: 20px;
        }
    }

    &__table {
        font-family: $f-future-book;
        font-weight: 400;
        letter-spacing: 0.05em;
        margin-bottom: 20px;
    }

    &__table-title {
        display: flex;
        flex-wrap: nowrap;
        padding: 0 10px 10px;

        @include bp(min-width, $bp-medium) {
            padding: 0 20px 8px;
        }
    }

    &__order-subtotal-title {
        flex: 0 0 70%;

        @include bp(min-width, $bp-medium) {
            flex-basis: 60%;
        }
    }

    &__table-main-title {
        font-size: 16px;
        line-height: 28px;
    }

    &__table-sub-title {
        font-size: 12px;
        line-height: 18px;
        padding-left: 4px;

        @include bp(min-width, $bp-medium) {
            padding-left: 8px;
        }
    }

    &__note {
        font: normal normal 11px/18px $f-avenir;
        letter-spacing: 0.05em;
        padding-left: 10px;

        @include bp(min-width, $bp-medium) {
            font-size: 12px;
            padding-left: 20px;
        }
    }
}

.furniture-pricing-row {
    display: flex;
    flex-wrap: nowrap;
    padding: 7px 10px 5px;
    font-size: 12px;
    line-height: 18px;
    color: $brand-v2-dark-gray;

    @include bp(min-width, $bp-medium) {
        padding: 7px 20px 5px;
    }

    &:nth-child(odd) {
        background: $collection-bg;
    }

    &__order-subtotal {
        flex: 0 0 70%;

        @include bp(min-width, $bp-medium) {
            flex-basis: 60%;
        }
    }
}

.furniture-protection-see-details-dialog {
    &__container {
        position: relative;
        color: $black;
        padding: 35px 16px 26px;

        @include bp(min-width, $bp-medium) {
            padding: 39px 34px 28px;
        }

        &.full {
            .furniture-protection-see-details-dialog {
                &__table {
                    display: block;
                }

                &__furniture-table {
                    display: table;
                }

                &__show-more {
                    &__show {
                        display: none;
                    }

                    &__hide {
                        display: inline-block;
                    }
                }
            }
        }
    }

    &__close {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 25px;
        height: 25px;
        transform: rotate(45deg);
        cursor: pointer;

        &::before,
        &::after {
            content: "";
            position: absolute;
            background: $black;
        }

        &::before {
            height: 1px;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &::after {
            width: 1px;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__sup-title {
        font: 500 normal 12px/14px $f-future-book;
        letter-spacing: 0.2em;
        margin-bottom: 12px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 16px;
        }
    }

    &__title {
        font: normal normal 24px/24px $f-classical-normal;
        margin-bottom: 26px;

        @include bp(min-width, $bp-medium) {
            font: normal normal 30px/30px $f-classical-normal;
            margin-bottom: 32px;
        }
    }

    &__plan-ul {
        font: normal normal 12px/18px $f-future-book;
        letter-spacing: 0.05em;
        padding-bottom: 14px;

        @include bp(min-width, $bp-medium) {
            font: normal normal 16px/28px $f-future-book;
            padding-bottom: 17px;
        }
    }

    &__plan-li {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        +.furniture-protection-see-details-dialog {
            &__plan-li {
                margin-top: 16px;

                @include bp(min-width, $bp-medium) {
                    margin-top: 20px;
                }
            }
        }

        .checkmark {
            margin-top: 2px;
            margin-right: 8px;

            @include bp(min-width, $bp-medium) {
                margin-top: 10px;
                margin-right: 17px;
            }
        }
    }

    &__plan-li-description {
        display: block;
        font: normal normal 12px/18px $f-future-book;
        color: $brand-v2-dark-gray;
        margin-top: 8px;
    }

    &__furniture-table {
        display: none;
        margin: 10px auto 20px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 30px;
        }

        thead {
            border-bottom: 1px solid $brand-v2-lt-gray;
        }

        tbody {
            font: normal normal 12px/18px $f-future-book;
            letter-spacing: 0.05em;
        }

        th,
        td {
            text-align: center;

            &:nth-child(1) {
                width: 100%;
                text-align: left;
            }

            &:nth-child(3) {
                padding-right: 8px;
                padding-left: 8px;

                @include bp(min-width, $bp-medium) {
                    padding-right: 40px;
                    padding-left: 40px;
                }
            }
        }

        th {
            font: normal normal 12px/14px $f-classical-normal;
            padding-bottom: 4px;

            @include bp(min-width, $bp-medium) {
                font: normal normal 16px/24px $f-classical-normal;
                letter-spacing: -0.01em;
                padding-bottom: 8px;
            }
        }

        td {
            padding: 8px 0;
            margin: 0;

            @include bp(min-width, $bp-medium) {
                padding: 2px 0;
            }

            &.sub {
                padding-left: 5px;

                @include bp(min-width, $bp-medium) {
                    padding-left: 20px;
                }
            }
        }

        .first-row {
            td {
                padding-top: 10px;

                @include bp(min-width, $bp-medium) {
                    padding-top: 20px;
                }
            }

            .mark {
                &::before {
                    top: calc(50% + 5px);

                    @include bp(min-width, $bp-medium) {
                        top: calc(50% + 10px);
                    }
                }
            }
        }

        .mark {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: $brand-v2-medlt-gray;
            }
        }
    }

    &__price-wrapper {
        display: flex;
        justify-content: space-between;
        font: 500 normal 14px/18px $f-avenir;
        border-top: 1px solid $brand-v2-lt-gray;
        padding-top: 16px;
        margin-bottom: 4px;

        @include bp(min-width, $bp-medium) {
            font-size: 12px;
            padding-top: 20px;
        }
    }

    &__price-title {
        letter-spacing: 1px;
        max-width: 70%;

        @include bp(min-width, $bp-medium) {
            max-width: 80%;
        }
    }

    &__table {
        display: none;
        font-family: $f-future-book;
        font-weight: 400;
        letter-spacing: 0.05em;
        margin: 0 -10px 20px;

        @include bp(min-width, $bp-medium) {
            margin: 0 40px 20px;
        }
    }

    &__table-title {
        display: flex;
        flex-wrap: nowrap;
        padding: 0 10px 10px;

        @include bp(min-width, $bp-medium) {
            padding: 0 20px 8px;
        }
    }

    &__order-subtotal-title {
        flex: 0 0 70%;

        @include bp(min-width, $bp-medium) {
            flex-basis: 60%;
        }
    }

    &__table-main-title {
        font-size: 16px;
        line-height: 28px;
    }

    &__table-sub-title {
        font-size: 12px;
        line-height: 18px;
        padding-left: 4px;

        @include bp(min-width, $bp-medium) {
            padding-left: 8px;
        }
    }

    &__note {
        font: normal normal 11px/16px $f-avenir;
        letter-spacing: 0.05em;
        margin: 13px 10px 16px;

        @include bp(min-width, $bp-medium) {
            font-size: 12px;
            line-height: 18px;
            margin: 8px auto 16px;
        }
    }

    &__show-more {
        font: normal normal 12px/20px $f-avenir;
        color: $brand-v2-dark-gray;
        cursor: pointer;
        text-decoration: underline;
        margin-bottom: 16px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 20px;
        }

        &__show {
            display: inline-block;
        }

        &__hide {
            display: none;
        }
    }

    &__add {
        width: 100%;
    }

    &__tandc {
        font: normal normal 10px/16px $f-avenir;
        color: $brand-v2-dark-gray;

        a {
            font-weight: 500;
            color: inherit;
            text-decoration: underline;

            &:focus,
            &:active {
                color: inherit;
            }
        }
    }

    .btn-default {
        color: $white;
    }
}
