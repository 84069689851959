.bonus-product-list {
    .bonus-product-item {
        @extend %clearfix;
        padding: 1em 0;
        border-bottom: 1px solid $gainsboro;
    }

    .quantity-error {
        color: red;
        margin-top: 6px;
        text-transform: inherit;
    }

    .bonus-product-list-footer {
        padding-top: 1em;
        padding-bottom: 1em;

        .add-to-cart-bonus {
            display: block;
            margin: 24px auto 0;

            @include bp(min-width, $bp-medium) {
                float: right;
            }
        }
    }

    .selected-bonus-item {
        margin: 1em 0;

        .item-name {
            padding-right: 24px;
            position: relative;
        }

        .remove-link {
            background: transparent;
            border: 0;
            height: 20px;
            min-width: initial;
            padding: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 20px;

            &::before {
                color: $clr_brand_black;
                content: "\00d7";
                font-size: 24px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.bonus-discount-container {
    .promo-details {
        display: none;

        &.visible {
            display: block;
        }
    }
}

#bonus-product-dialog {
    .promotion {
        border-top: 0;
        margin: 0 0 1em;
        padding-top: 0;
        width: 100%;
    }

    .item-price {
        margin: 10px 0;
    }

    .product-add-to-cart {
        margin: 0;
        float: none;

        .inventory {
            label {
                display: none;
            }
        }

        button {
            display: inline-block;
            margin: 0 0 0 10px;
        }
    }

    .bonus-product-list {
        .bonus-product-item {
            .product-col-1,
            .product-col-2 {
                @include bp(max-width, $bp-medium) {
                    .choices ul {
                        text-align: center;

                        li {
                            float: none;
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    .swatch-overlay {
        display: none;
        white-space: nowrap;
        position: absolute;
        background-color: $white;
        border: 1px solid $brand-v2-lt-gray;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        padding: 15px;
        z-index: 10000;
        line-height: 1.2;
        font-family: $f-primary;

        &.active {
            display: inline-block;
            top: 50%;
            right: calc(100% + 10px);
            transform: translate(0, -50%);
        }
    }
}

.bonus-qty {
    margin-right: 15px;
    padding: 0 37px;
}

.more-bonus-products {
    margin-top: 14px;
}
