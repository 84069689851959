.alert,
[data-alert] {
    display: none;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 12px;
    font-size: 16px;
    background-color: #eee;
    color: #444;

    &.active {
        display: table;
    }

    &.alert-error {
        background-color: $c-error-background;
        color: darken($c-error, 10%);
    }

    &.alert-success {
        background-color: $c-green;
        color: $white;
    }

    & > div {
        display: table-cell;
        vertical-align: middle;
        padding: 4px 15px;
        text-align: left;
        color: inherit;
    }

    & > [data-alert-destroy] {
        width: 50px;
        text-align: right;
    }

    .sg-form > & {
        margin-top: 20px;
    }
}

[data-alert-message] > p {
    margin: 0 !important;
}

[data-alert-destroy] {
    font-size: 22px;
    width: 30px;
    text-align: center;
    padding: 0 8px;

    & a {
        color: inherit;
        text-decoration: none !important;
    }
}
