@media print {
    .pt_order-confirmation #mini-cart,
    .pt_order-confirmation .menu-utility-user,
    .pt_order-confirmation .menu-utility,
    .pt_order-confirmation .header-banner,
    .pt_order-confirmation #navigation,
    .wishlist,
    .breadcrumb,
    .product-price,
    .product-name.desktop-only,
    .product-number.desktop-only,
    .pdp-overlay-container,
    .product-accordions,
    .pdp-dual-slot-container,
    .pdp-predictive-recommendations,
    .pdp-recently-viewed,
    .empty-cart-recently-viewed,
    .no-hits-recently-viewed,
    .complete-look,
    .BTT,
    .no-print,
    #launcher,
    .LPMcontainer,
    .LPMoverlay {
        display: none !important;
    }

    .product-detail-container {
        margin-top: 0 !important;
        padding: 0 !important;
        .product-col-2 {
            .product-addtocart-wrapper {
                margin-top: 0;
                #printMain {
                    padding: 0 29px;
                }
            }
        }

    }

    .product-name.mobile-only,
    .product-number.mobile-only {
        display: inline-block !important;
        vertical-align: middle;
    }

    .pdp-main .print-style-number {
        display: inline-block;
        font-size: 10px;
    }

    .pdp-main .product-name.mobile-only {
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 24px;
    }

    .pdp-main .product-number.mobile-only {
        float: right;
        line-height: 24px;
        margin-bottom: 0;
        color: $brand-primary-light;

        .productID {
            color: inherit;
        }
    }

    .product-thumbnails {
        .thumb {
            margin: 0;

            &.selected {
                .thumbnail-link {
                    border: none;
                }
            }
        }
    }
}
