.cms-page {
    padding-top: 20px;

    p {
        margin: 0 0 20px;
    }

    @include bp(max-width, $bp-medium) {
        [class*="grid-span"] {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .one-col {
        // set to 80% width of grid-container
        @include max-width($bp-large);
        float: none;
    }

    .two-col-left {
        .grid-span-7 {
            float: right;
            margin-right: 0;
        }
    }

    .three-col {
        .grid-span-4 {
            float: none;
            display: inline-block;
        }

        .grid-span-3:last-child {
            float: right;
        }
    }

    .grid-container button {
        @include plain-button;
    }
}
