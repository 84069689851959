.swatch-category-wrapper {
    padding-bottom: 80px;
    margin: 20px 0 25px;
    border-bottom: 1px solid $brand-light-grey-2;
}

.swatch-category-name {
    font: 24px/24px $f-classical-normal;
    padding-bottom: 25px;
    text-transform: capitalize;
}

.swatch-category-description,
.swatch-info-asset p {
    font: 16px/28px $f-primary;
    padding-bottom: 40px;
}

.swatch-anchor-accordion {
    margin-bottom: 30px;

    &__head {
        background: $alabaster;
        color: $black;
        cursor: pointer;
        font: normal normal 16px/16px $f-classical-normal;
        letter-spacing: 0.025em;
        padding: 8px 20px;
        position: relative;
        text-transform: none;

        @include bp(min-width, $bp-large) {
            font-size: 18px;
            letter-spacing: 0.035em;
            line-height: 26px;
        }

        &::after {
            border: solid $black;
            border-width: 0 3px 3px 0;
            content: "";
            padding: 3px;
            position: absolute;
            right: 20px;
            top: 40%;
            transform: rotate(225deg);
            transition: all 0.3s ease;
        }
    }

    &__body {
        padding: 0 20px;
    }

    &.closed {
        .swatch-anchor-accordion {
            &__head {
                &::after {
                    transform: rotate(45deg) translateY(-50%);
                }
            }

            &__body {
                display: none;
            }
        }
    }
}

.swatch-anchor {
    &__menu {
        align-items: center;
        color: $black;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        list-style-type: none;
        text-align: center;

        @include bp(min-width, $bp-medium) {
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto 10px;
        }
    }

    &__list-item {
        padding: 5px 20px;

        @include bp(min-width, $bp-medium) {
            padding: 5px 30px;
        }
    }

    &__link {
        color: inherit;
        font: normal normal 16px/16px $f-avenir;
        letter-spacing: 0.025em;
        text-decoration: none;

        &:active,
        &:focus,
        &:hover {
            color: inherit;
        }
    }

    &__break {
        background-color: $black;
        height: 1px;
        margin: 10px 0;
        position: relative;
        width: 15px;

        @include bp(min-width, $bp-medium) {
            height: 15px;
            width: 1px;
        }
    }
}

.swatches-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .swatch {
        width: 45%;
        margin: 25px 0;
        box-sizing: border-box;
        display: inline-flex;
        flex-flow: column;
        justify-content: space-between;
    }
}

.swatch-image {
    width: 100%;
    height: auto;
}

.swatch-info {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swatch-tooltip {
    display: block;
}

.swatch-name {
    font: 12px/18px $f-primary;
    color: $black;
    text-transform: capitalize;
    word-break: break-word;
    text-align: center;
}

.swatch-description {
    min-width: 16px;
    padding-left: 10px;
    box-sizing: content-box;
}

.button-swatch {
    border: 1px solid $black;
    color: $black;
    background: $white;
    font: 12px/16px $f-primary;
    padding: 12px 30px;
    text-transform: none;
    height: auto;
    align-self: center;
    position: relative;

    &:hover {
        background: $black;
        color: $white;
        text-decoration: none;
    }

    &:focus {
        background: $white;
        color: $black;
        text-decoration: none;
    }

    &.remove-swatch,
    &.add-swatch {
        padding: 12px 25px 12px 35px;

        &::before {
            content: "+";
            font-size: 15px;
            line-height: 15px;
            left: 11px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &.remove-swatch,
    &.submit-swatch {
        color: $white;
        background: $black;

        &:hover {
            background: $white;
            color: $black;
            text-decoration: none;
        }

        &:focus {
            background: $black;
            color: $white;
            text-decoration: none;
        }
    }

    &.remove-swatch {
        &::before {
            top: 48%;
            transform: rotate(-45deg) translate(50%, -25%);
        }

        &[disabled] {
            background: $very-light-gray;
        }
    }

    &.submit-swatch {
        &[disabled] {
            background: $silver;
            border-color: $silver;
            color: $brand-v2-off-black;

            span {
                border: transparent !important;
            }
        }
    }
}

.quick-add-swatches {
    margin: -40px auto 20px;

    @include bp(min-width, $bp-large) {
        margin-top: -30px;
    }

    &__head {
        background: $alabaster;
        color: $black;
        cursor: pointer;
        font: normal normal 16px/16px $f-classical-normal;
        letter-spacing: 0.025em;
        padding: 8px 20px;
        position: relative;
        text-transform: none;

        @include bp(min-width, $bp-large) {
            font-size: 18px;
            letter-spacing: 0.035em;
            line-height: 26px;
        }

        &::after {
            border: solid $black;
            border-width: 0 3px 3px 0;
            content: "";
            padding: 3px;
            position: absolute;
            right: 20px;
            top: 40%;
            transform: rotate(225deg);
            transition: all 0.3s ease;
        }
    }

    &__body {
        padding: 14px 20px 20px;
    }

    &__description {
        color: $black;
        font: normal normal 14px/16px $f-avenir;
        letter-spacing: 0.0025em;
    }

    &__ctas {
        align-items: center;
        display: flex;
        flex-direction: column;

        @include bp(min-width, $bp-medium) {
            flex-direction: row;
        }
    }

    &.closed {
        .quick-add-swatches {
            &__head {
                &::after {
                    transform: rotate(45deg) translateY(-50%);
                }
            }

            &__body {
                display: none;
            }
        }
    }
}

.button-swatch-pack {
    align-items: center;
    color: $black;
    background-color: $white;
    border: 1px solid $black;
    display: flex;
    font: normal normal 16px/16px $f-avenir;
    justify-content: center;
    letter-spacing: 0.025em;
    margin-top: 16px;
    min-width: 270px;
    padding: 11px 41px;
    text-align: center;
    text-transform: none;

    @include bp(min-width, $bp-medium) {
        margin-right: 40px;
        margin-top: 20px;
    }

    &:active,
    &:focus,
    &:hover {
        background-color: $black;
        color: $white;
    }
}

.selected-swatches-ctas {
    align-items: center;
    color: $black;
    display: flex;
    justify-content: center;

    @include bp(min-width, $bp-medium) {
        margin-bottom: 21px;
    }
}

.selected-swatches-button {
    margin-bottom: 16px;
    text-align: center;
    text-decoration: none;

    @include bp(min-width, $bp-medium) {
        margin-bottom: 0;
    }
}

.clear-selected-swatches {
    color: inherit;
    margin: 0 20px 16px;

    @include bp(min-width, $bp-medium) {
        margin-bottom: 0;
    }

    &:active,
    &:focus,
    &:hover {
        color: inherit;
    }
}

.selected-swatches-label {
    font: 12px/18px $f-primary;
    margin-bottom: 10px;
    padding-bottom: 0;
    text-transform: capitalize;
}

.selected-swatches-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.selected-swatch {
    max-width: 20%;
    box-sizing: border-box;
    position: relative;
    padding: 0 2.5px;
    margin: 0 0 10px;

    .swatch-category {
        color: $scorpion;
        font: 12px/18px $f-primary;
        text-align: center;
        word-wrap: break-word;
    }

    .remove-swatch {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 20px;
        height: 20px;
        min-width: 20px;
        padding: 0;
        border-radius: 50%;
        background: $white;

        &:hover {
            opacity: 1;
        }

        &::before,
        &::after {
            position: absolute;
            left: 8px;
            top: 4px;
            content: "";
            height: 10px;
            width: 1.5px;
            background-color: #333;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}

.swathcrequest-label {
    margin-top: 55px;
    text-transform: none;
    font: 36px/36px $f-classical-normal;
    padding-bottom: 25px;
}

.swatch-request-form-anchor {
    visibility: hidden;
    height: 0;
    width: 0;
}

.selected-swatches-error-message {
    color: $swatches-selected-swatches-error-message-color;
    margin-bottom: 20px;
}

.submit-swatch {
    width: 100%;
}

.button-swatch-success {
    display: none;
}

.order-sent {
    .swatch-category-description,
    .formContainer,
    .button-swatch-default {
        display: none;
    }

    .button-swatch-success {
        display: inline;
    }
}

.selectableLabel.second {
    margin-top: 20px;
}

.selectableContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.selectableColumn {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 20px;
}

.selectableOption {
    height: 35px;
}

.sp-nowr {
    white-space: nowrap;
}

.colorListContainer {
    margin-bottom: 40px;
}

.selected-swatches-block {
    position: relative;
    border-top: 1px solid $brand-light-grey-2;
}

.selected-swatches-arrow {
    position: absolute;
    border: solid $black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    background: transparent;
    padding: 3px;
    top: 10px;
    left: 50%;
    height: auto;
    min-width: auto;
    transform: translate(-50%, 0);
    transform: rotate(45deg);

    &:hover,
    &:focus {
        border: 0 solid $black;
        border-width: 0 3px 3px 0;
        background: transparent;
    }
}


@include bp(max-width, $bp-medium) {
    .desktop {
        display: none;
    }

    label {
        display: inline;
        font-size: 10px;
    }

    .primary-content-swatch {
        padding: 0 20px !important;
    }

    .sticky-mob-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: $white;
        z-index: 10;
        padding: 25px 20px 0;
    }

    .selected-swatches-block {
        padding-top: 25px;
    }

    .selected-swatches-block--closed {
        .swatch-name,
        .swatch-category,
        .remove-swatch {
            display: none;
        }

        .selected-swatch {
            max-width: 10%;
        }

        .selected-swatches-arrow {
            transform: rotate(-135deg);
        }
    }

    .selected-swatches {
        .swatch-category {
            font-size: 10px;
        }
    }
}

@include bp(min-width, $bp-medium) {
    .mobile {
        display: none;
    }

    label {
        display: inline;
        white-space: nowrap;
        font-size: 14px;
    }

    .primary-content-swatch {
        display: flex;
        padding: 0;
    }

    .left-part,
    .right-part {
        width: 50%;
    }

    .left-part {
        padding: 0 20px;
    }

    .right-part {
        &.reverse {
            display: flex;
            flex-flow: column-reverse;
        }
    }

    .swatches-wrapper {
        justify-content: flex-start;

        .swatch {
            width: 30%;

            &:nth-child(3n - 1) {
                margin: 25px 5%;
            }
        }
    }

    .sticky-wrapper {
        position: fixed;
        top: 0;
    }

    .selected-swatches-block {
        max-width: 560px;
        border: 1px solid $brand-light-grey-2;
        padding: 0 30px;
        text-align: center;
    }

    .selected-swatches-arrow {
        display: none;
    }

    .selected-swatches-label {
        font: 30px/30px $f-classical-normal;
        padding-bottom: 25px;
        text-align: left;
        margin-top: 50px;
    }

    .selected-swatches-description {
        padding: 10px 0;

        &::before {
            content: "";
            width: 100%;
            left: 0;
            position: absolute;
            margin-top: -15px;
            height: 2px;
            border-top: 1px solid $brand-light-grey-2;
        }
    }
}

.order-swatch-success-message {
    font: normal normal 900 14px/18px $f-avenir;
    color: $swatches-request-success-message-color;
    padding-top: 10px;
    text-align: center;
}

.order-swatch-submit {
    position: relative;
}

.order-swatch-success-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: transparent;
}
