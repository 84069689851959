@include bp(max-width, $bp-medium) {
    .print-page {
        display: none !important;
    }
    .tooltip {
        font-size: .9em;
    }
    .form-field-tooltip {
        float: none;
        margin: 0 0 10px 5px;
        width: auto;
    }
    .ui-dialog {
        max-width: 98%;
        .ui-dialog-content {
            height: auto !important;
            overflow: visible;
        }
    }
    .scrollable {
        height: auto;
        li {
            float: left;
            a {
                border: 1px solid #d9d9d9;
                border-radius: 6px;
                display: block;
                padding: .2em .8em;
            }
        }
    }
    #header {
        height: auto;
        padding: 1% 1% 30px;
        .primary-logo {
            float: left;
            position: relative;
            top: auto;
            width: 65%;
            img {
                max-width: 100%;
            }
        }
    }
    .mini-cart-products {
        max-height: 9999px;
    }
    #quickviewbutton {
        display: none !important;
    }
    .product-tile {
        height: auto !important;
    }
    .wide-tiles .product-tile {
        .product-image {
            width: 41%;
        }
        .product-name,
        .product-pricing,
        .product-promo,
        .pr_stars,
        .product-swatches {
            float: right;
        }
        .product-name h2 {
            margin-top: 0;
        }
    }
    .product-compare,
    .compareitems {
        display: none !important;
    }
    .pt_product-details {
        .primary-content {
            padding-bottom: 68px;
        }
        .breadcrumb .last {
            display: none;
        }
    }
    #product-nav-container {
        bottom: 0;
        width: 100%;
        div {
            width: 60px;
            &.product-next {
                float: right;
            }
        }
        .divided span {
            border: 0;
        }
    }
    .ui-dialog .ui-dialog-content {
        padding: .25em;
    }
    .pt_cart #secondary {
        display: none;
    }
    .order-summary-footer h2 {
        width: 50%;
    }
    .place-order-totals .order-totals-table {
        width: 100%;
    }
    .pt_order-confirmation {
        .item-list {
            th {
                display: none;
            }
            td {
                float: left;
                padding: 1em 0;
                width: 98%;
            }
            .order-totals-table td {
                float: left;
                width: 48%;
            }
        }
        .order-shipment-table {
            th {
                display: none;
            }
            td {
                font-size: .9em;
                box-sizing: border-box;
                float: left;
            }
            .line-item-details {
                width: 70%;
            }
            .line-item-quantity {
                width: 10%;
            }
            .line-item-price {
                width: 20%;
            }
            .order-shipment-details {
                border: none;
                width: 100%;
            }
            .shipping-method {
                padding-left: 0;
            }
        }
    }
    .content-asset ul.account-options li {
        float: none;
        width: auto;
    }
    .address-list li,
    .payment-list li {
        float: none;
        width: auto;
    }
    .page-content-tab-navigaton button {
        width: 100%;
    }
    .pt_gift-registry {
        .add-gift-cert {
            p {
                width: 100%;
            }
        }
        .create-new-registry {
            overflow: hidden;
            p {
                display: none;
            }
        }
        .item-list {
            width: 98%;
            th {
                display: none;
            }
        }
    }
    .order-shipment-table .section-header {
        font-size: .9em !important;
        padding: .3em;
    }
    table.item-list .section-header,
    .order-shipment-table .section-header {
        background-image: none;
    }
    .list-table-header {
        padding: 1em !important;
        .button {
            display: block;
            margin: 20px 0;
            width: 196px;
        }
        p {
            display: none;
        }
        button {
            display: block;
            width: 260px;
        }
    }
    .home-bottom-left,
    .home-bottom-center {
        width: 100% !important;
    }
    .home-bottom-right {
        clear: both;
        float: none;
        width: 100% !important;
        .product-tile {
            min-height: 0;
        }
    }
    .product-tile .product-image {
        height: auto;
    }
    .facebook-registration {
        left: -3px;
        position: relative;
        width: 100.7% !important;
        .fb_iframe_widget {
            position: relative;
            width: 100.7% !important;
            span {
                position: relative;
                width: 100.7% !important;
                iframe {
                    position: relative;
                    width: 100.7% !important;
                }
            }
        }
    }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
    .product-tile .pr_stars {
        left: 40px;
    }
    .wide-tiles .product-tile {
        .product-name h2 {
            margin-top: 10px;
        }
        .product-image {
            width: 38%;
        }
    }
    .facebook-registration {
        left: 0;
        position: relative;
        width: 100% !important;
        .fb_iframe_widget {
            position: relative;
            width: 100% !important;
            span {
                position: relative;
                width: 100% !important;
                iframe {
                    position: relative;
                    width: 100% !important;
                }
            }
        }
    }
    #preferred-store-panel input[type="text"] {
        width: 49.5966%;
    }

    .pt_content-search-result {
        .folder-content-list {
            li {
                float: none;
                width: 100%;
                clear: both;
            }
        }
        .pagination {
            clear: both;
            float: none;
            .results-hits {
                float: none;
            }
        }
    }

}

@media screen and (min-width: 768px) and (max-width: 959px) {
    .primary-focus {
        .primary-content {
            width: 540px;
        }
        #secondary {
            width: 190px;
        }
    }
    .html-slot-container img,
    .bottom-banner-cell img {
        max-width: 100%;
    }
    .ui-dialog {
        max-width: 98%;
        .ui-dialog-content {
            height: auto !important;
            overflow: visible;
        }
    }
    .scrollable {
        height: auto;
    }
    .mini-cart-total-label {
        display: none;
    }
    ul.search-result-items {
        .new-row {
            clear: none;
        }
    }
    .pdp-main {
        .product-set-list .block-add-to-cart button {
            font-size: .9em;
            margin-top: 3px;
        }
    }
    table .section-header {
        font-size: 1em !important;
    }
    #compare-table td .product-tile {
        width: 79px;
    }
    .pt_gift-registry .add-gift-cert p {
        width: auto;
    }
    .home-bottom-right .product-tile {
        min-height: 0;
    }
    .facebook-registration {
        left: 0;
        position: relative;
        width: 100% !important;
        .fb_iframe_widget {
            position: relative;
            width: 100% !important;
            span {
                position: relative;
                width: 100% !important;
                iframe {
                    position: relative;
                    width: 100% !important;
                }
            }
        }
    }
    .mini-cart-item-word {
        span {
            display: none;
        }
    }

}

@media screen and (max-width: 480px) {
    #preferred-store-panel input[type="text"] {
        width: 49.5966%;
    }

    .pt_content-search-result {
        .folder-content-list {
            li {
                float: none;
                width: 100%;
                clear: both;
            }
        }
        .pagination {
            clear: both;
            float: none;
            .results-hits {
                float: none;
            }
        }
    }

}
