.js {
    .loader {
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999999;
    }

    .loader-bg {
        background: url("../images/interface/ui-overlay-shadow.png") repeat scroll 50% 50% $night-rider;
        height: 100%;
        opacity: 0.4;
        width: 100%;
    }

    .loader-indicator {
        background: url("../images/MGBW-page-loading-animation.gif") center / 40px no-repeat $clr_white;
        border: 3px solid $clr_white;
        border-radius: 50%;
        height: 50px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
    }
}
