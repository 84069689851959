// Styles for design-request.isml Start
.design-services {
    &__wrapper {
        color: $black;
        padding: 0;
    }

    &__body {
        display: flex;
        flex-direction: column;
        margin: 0 auto 41px;
        max-width: 1600px;
        width: 100%;

        @include bp(min-width, $bp-large) {
            flex-direction: row;
            margin-bottom: 77px;
        }
    }

    &__body-column {
        @include bp(min-width, $bp-large) {
            width: 50%;
        }

        &__left {
            img {
                display: block;
                width: 100%;
            }
        }

        &__right {
            background: $alabaster;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 16px 24px 34px;
            text-align: center;
        }
    }

    &__title {
        color: inherit;
        font: normal normal 24px/26px $f-classical-normal;
        letter-spacing: 2.29px;
        margin: 0 auto 14px;
        max-width: 470px;
        text-transform: none;

        @include bp(min-width, $bp-large) {
            font: normal normal 36px/52px $f-classical-normal;
            letter-spacing: 1.8px;
            margin-bottom: 8px;
        }
    }

    &__instruction {
        font: normal normal 12px/18px $f-avenir;
        letter-spacing: 0.67px;
        margin: 0 auto 24px;
        max-width: 600px;

        @include bp(min-width, $bp-large) {
            font: normal normal 14px/28px $f-avenir;
            letter-spacing: 0;
        }
    }

    &__form-container {
        float: initial;
        margin: 0 auto;
        max-width: 285px;
        padding: 0;

        @include bp(min-width, $bp-large) {
            max-width: 644px;
        }

        > [data-alert] {
            margin: 0 0 30px;
        }
    }

    &__footer {
        padding-bottom: 16px;

        @include bp(min-with, $bp-large) {
            padding-bottom: 87px;
        }
    }
}

.process-design-request-form {
    &__inputs {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;

        .form-row {
            width: 100%;

            @include bp(min-width, $bp-large) {
                width: 50%;
            }
        }

        input[type="email"],
        input[type="tel"],
        input[type="text"] {
            &.input-text {
                border-color: $design-services-input-border-color;
                color: inherit;
                height: 40px;
                padding: 0 12px;

                &.error {
                    border-color: $field-border-error;
                }
            }
        }
    }

    &__checkbox {
        margin: 0 -12px;

        .form-row {
            margin-top: -16px;
        }
    }

    .form-row {
        margin-bottom: 16px;
        padding: 0 12px;

        &.input-focused,
        &.dropdown {
            > label {
                &:first-of-type {
                    transform: translate3d(-7px, -30px, 0);
                }
            }
        }

        label {
            color: $design-services-input-label-color;
            font: normal normal 12px/26px $f-avenir;
            left: 20px;
            letter-spacing: 0.67px;
            top: 8px;

            @include bp(min-width, $bp-large) {
                font: normal normal 14px/38px $f-avenir;
                letter-spacing: 0.78px;
                top: 1px;
            }

            &.checkbox-label {
                color: inherit;
                font: normal normal 12px/20px $f-avenir;
                left: initial;
                letter-spacing: 0.67px;
                padding-left: 30px;
                top: initial;

                @include bp(min-width, $bp-large) {
                    font: normal normal 14px/28px $f-avenir;
                    letter-spacing: 0;
                    padding-left: 35px;
                }

                &::before,
                &::after {
                    top: 50%;
                    transform: translateY(-50%);
                }

                &::before {
                    border-color: $design-services-checkbox-border-color;

                    @include bp(min-width, $bp-large) {
                        width: 25px;
                        height: 25px;
                    }
                }

                &::after {
                    @include bp(min-width, $bp-large) {
                        left: 6px;
                    }
                }
            }

            &.custom-select {
                .required-indicator {
                    &::before {
                        line-height: 14px;
                    }
                }
            }

            .required-indicator {
                margin: 6px 0 0;

                @include bp(min-width, $bp-large) {
                    margin-top: 10px;
                }
            }
        }
    }

    .ba-select-container {
        line-height: 0;
    }

    .ba-shiv {
        align-items: center;
        border-color: $design-services-input-border-color;
        color: inherit;
        display: flex;
        font: normal normal 14px/19px $f-avenir;
        height: 40px;
        padding: 0 52px 0 10px;

        @include bp(min-width, $bp-large) {
            font: normal normal 15px/20px $f-avenir;
        }

        .ba-shiv-content {
            color: inherit;
            font: inherit;
            height: auto;
        }
    }

    .ba-over {
        &.open {
            .ba-options {
                padding: 13px 61px 11px 22px;

                &::after {
                    border: 0 none;
                }
            }
        }
    }

    .ba-options {
        border-color: $design-services-input-border-color;
        max-height: 38px;
        padding: 0;

        &::after {
            border-color: $design-services-input-border-color;
            bottom: 4px;
        }

        .ba-arrow {
            top: 15px;

            &::before {
                color: inherit;
            }
        }

        li {
            color: inherit;
            text-align: initial;
        }
    }

    .button {
        font: normal normal 14px/28px $f-avenir;
        letter-spacing: 1.6px;
        height: 28px;
        max-width: 210px;
        padding: 0;
        text-transform: none;
        width: 100%;

        @include bp(min-width, $bp-large) {
            height: 41px;
            max-width: 245px;
        }
    }
}
// Styles for design-request.isml End

// Styles for design-request-qudini.isml Start

.design-services-qudini {
    &__header {
        padding: 40px 15px 15px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &__image {
        max-width: 200px;
        margin-bottom: 40px;
    }

    &__title {
        color: inherit;
        font: normal normal 24px/26px $f-classical-normal;
        letter-spacing: 2.29px;
        margin-bottom: 14px;
        text-transform: none;

        @include bp(min-width, $bp-large) {
            font: normal normal 32px/52px $f-classical-normal;
            letter-spacing: 1.8px;
            margin-bottom: 20px;
        }
    }

    &__description {
        max-width: 1000px;
        font: normal normal 16px/30px $f-avenir;
    }

    &__body {
        padding: 0 15px;
        display: flex;
        flex-flow: column;
        max-width: 1440px;
        margin: 15px auto;

        @include bp(min-width, $bp-large) {
            flex-flow: row;
            padding-bottom: 50px;
        }
    }
}

.quidini-tile {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    background: $alabaster;
    border: 3px solid $flyout-refinement-border-color;
    border-radius: 10px;
    padding: 20px 25px 15px;
    margin: 0 0 30px;

    @include bp(min-width, $bp-large) {
        padding: 60px 54px 10px;
        margin: 0 20px;
    }

    &__header {
        font: normal 900 24px/32px $f-avenir;
        margin: 15px 0;
    }

    &__subheader {
        font: normal 500 18px/24px $f-avenir;
        color: $dim-gray;
        margin-bottom: 30px;
    }

    &__content {
        font: normal 500 16px/26px $f-avenir;
        padding-bottom: 40px;
    }

    &__cta {
        display: flex;
        align-items: center;
    }

    &__comment {
        margin-top: 20px;
        color: $very-middle-gray-v5;
    }
}

// Styles for design-request-qudini.isml End

.ds-layout {
    &__wrapper {
        &__filled {
            background: $alabaster;
        }
    }

    &__inner {
        margin-right: auto;
        margin-left: auto;
        text-align: center;

        &__1 {
            margin-bottom: 42px;
            max-width: 1304px;
            padding: 0 12px;
            position: relative;

            @include bp(min-width, $bp-large) {
                margin-bottom: 98px;
                padding: 0;
            }

            &::after {
                background: $black;
                bottom: 0;
                content: "";
                height: 1px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                width: 150px;

                @include bp(min-width, $bp-large) {
                    display: none;
                }
            }

            .ds-title {
                color: inherit;
                font: normal normal 24px/26px $f-classical-normal;
                letter-spacing: 2.29px;
                margin-bottom: 14px;

                @include bp(min-width, $bp-large) {
                    font: normal normal 36px/52px $f-classical-normal;
                    letter-spacing: 1.8px;
                    margin-bottom: 20px;
                }
            }

            .ds-description {
                color: inherit;
                font: normal normal 12px/18px $f-avenir;
                letter-spacing: 0.67px;
                margin: 0 auto 37px;
                max-width: 880px;

                @include bp(min-width, $bp-large) {
                    font: normal normal 14px/28px $f-avenir;
                    letter-spacing: 0;
                    margin-bottom: 59px;
                }
            }

            .ds-tiles {
                align-items: flex-start;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .ds-tile {
                align-items: center;
                display: flex;
                flex-direction: column;
                margin-bottom: 44px;
                padding-right: 8px;
                padding-left: 8px;
                width: 50%;

                @include bp(min-width, $bp-large) {
                    margin-bottom: 0;
                    width: 25%;
                }

                p {
                    color: inherit;
                    font: 500 normal 12px/12px $f-avenir;
                    letter-spacing: 2.25px;
                    margin: 22px 0 0;

                    @include bp(min-width, $bp-large) {
                        font: 900 normal 14px/26px $f-avenir;
                        letter-spacing: 2.89px;
                    }
                }
            }
        }

        &__2 {
            max-width: 1129px;

            @include bp(min-width, $bp-large) {
                margin-bottom: 51px;
            }

            .ds-title {
                color: inherit;
                font: normal normal 24px/26px $f-classical-normal;
                letter-spacing: 2.29px;
                margin-bottom: 14px;

                @include bp(min-width, $bp-large) {
                    font: normal normal 36px/36px $f-classical-normal;
                    letter-spacing: 1.8px;
                    margin-bottom: 9px;
                }
            }

            .ds-description {
                color: inherit;
                font: normal normal 12px/18px $f-avenir;
                letter-spacing: 0.67px;
                margin-bottom: 20px;
                padding: 0 44px;

                @include bp(min-width, $bp-large) {
                    font: normal normal 14px/28px $f-avenir;
                    letter-spacing: 0;
                    margin-bottom: 40px;
                }
            }

            .ds-image {
                margin: 0 auto;
                max-width: 941px;

                img {
                    display: block;
                    width: 100%;
                }
            }
        }

        &__3 {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 1025px;
            padding: 26px;

            @include bp(min-width, $bp-large) {
                flex-direction: row;
                height: 212px;
                padding: 60px;
            }

            .ds-button-wrapper {
                align-items: center;
                display: flex;
                justify-content: center;

                @include bp(min-width, $bp-large) {
                    padding-right: 70px;
                }

                &:last-child {
                    @include bp(min-width, $bp-large) {
                        padding-right: 0;
                    }
                }

                + .ds-button-wrapper {
                    margin-top: 16px;

                    @include bp(min-width, $bp-large) {
                        border-left: 1px solid $black;
                        margin-top: 0;
                        padding-left: 70px;
                    }
                }
            }

            .ds-button {
                align-items: center;
                border: 1px solid $black;
                display: flex;
                font: normal normal 14px/28px $f-avenir;
                justify-content: center;
                height: 28px;
                letter-spacing: 1.6px;
                text-decoration: none;
                width: 222px;

                @include bp(min-width, $bp-large) {
                    height: 41px;
                    width: 245px;
                }

                &:focus {
                    color: inherit;
                }

                &:hover {
                    background: $black;
                    color: $white;
                }
            }
        }

        &__4 {
            margin-top: 28px;
            max-width: 1458px;

            @include bp(min-width, $bp-large) {
                margin-top: 56px;
            }

            .ds-title {
                color: inherit;
                font: normal normal 24px/24px $f-classical-normal;
                letter-spacing: 2.29px;
                margin-bottom: 21px;

                @include bp(min-width, $bp-large) {
                    font: normal normal 36px/36px $f-classical-normal;
                    letter-spacing: 1.8px;
                    margin-bottom: 44px;
                }
            }
        }
    }
}

.ds-layout-carousel {
    display: flex;
    justify-content: center;

    &__row {
        position: relative;
        width: 100%;
    }

    &__controls {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 107px;
        background: transparent;

        @include bp(min-width, $bp-large) {
            height: 220px;
        }
    }

    .custom-owl-prev {
        left: -50px;
        transform: translateY(-50%) rotate(135deg);

        @include bp(min-width, $bp-large) {
            left: -40px;
        }
    }

    .custom-owl-next {
        right: -50px;
        transform: translateY(-50%) rotate(-45deg);

        @include bp(min-width, $bp-large) {
            right: -40px;
        }
    }

    .custom-owl-prev,
    .custom-owl-next {
        display: none;
        position: absolute;
        top: 50%;
        border-right: 1px solid $black;
        border-bottom: 1px solid $black;
        cursor: pointer;

        @include bp(min-width, $bp-medium) {
            display: block;
            width: 14px;
            height: 14px;
        }

        @include bp(min-width, $bp-large) {
            width: 22.5px;
            height: 22.5px;
        }
    }

    .owl-stage {
        display: flex;
        margin-left: -30px;

        @include bp(min-width, $bp-medium) {
            margin-left: 0;
        }
    }

    .owl-item {
        display: flex;
        flex: 1 0 auto;
        justify-content: center;
    }

    .owl-controls {
        display: none;
    }
}

.ds-layout-carousel-tile {
    height: 100%;

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    &__link-image {
        height: 107px;
        margin-bottom: 8px;

        @include bp(min-width, $bp-medium) {
            margin-bottom: 16px;
        }

        @include bp(min-width, $bp-large) {
            height: 220px;
        }

        &:hover {
            + .ds-layout-carousel-tile__bottom {
                .ds-layout-carousel-tile__cta {
                    text-decoration: underline;
                }
            }
        }
    }

    &__image {
        max-height: 100%;
    }

    &__bottom {
        display: flex;
        text-align: center;
        color: $black;
    }

    &__cta {
        color: inherit;
        font: 500 normal 10px/12px $f-avenir;
        letter-spacing: 1.88px;
        text-decoration: none;

        @include bp(min-width, $bp-large) {
            font: 900 normal 14px/22px $f-avenir;
            letter-spacing: 2.5px;
        }

        &:focus {
            color: inherit;
        }

        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }
}
